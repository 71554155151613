.dashboard-configuration-button-place {
  min-width: 200px;
  width: max-content;
  height: max-content;
  position: relative;
  min-height: 40px;
  pointer-events: none;
}

.dashboard-configuration-button-place #voiceable_agent_btn {
  position: relative !important;
  z-index: 1 !important;
  top: 0 !important;
  left: 0 !important;
}

.settings-voiceable-button-container {
  flex: 1;
  padding-left: 10%;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

#voiceable-contact-form {
  position: relative !important;
  z-index: 1 !important;
  top: 0 !important;
  left: 0 !important;
}

#voiceable-schedule {
  position: relative !important;
  z-index: 1 !important;
  top: 0 !important;
  left: 0 !important;
}

#voiceable_ai_button,
.voiceable-feedback {
  display: none !important;
}
