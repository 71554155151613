.conversations-list {
  height: calc(88vh - 70px);
  padding-top: 0px !important;
  padding-right: 0px !important;
  margin: 0rem -1.5rem;
  position: relative;
}
.conversations-list .divider {
  margin: 0 !important;
}
.conversations-list > .list {
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 0px !important;
  height: calc(100% - 115px);
}

.conversations-list h3 {
  padding-left: 1.5rem !important;
  margin-bottom: 5px !important;
}

.conversations-list .checkbox {
  padding-left: 1.5rem !important;
  padding-bottom: 10px;
}

.conversation div.description {
  color: rgba(62, 62, 62, 1);
  font-size: 15pt;
  font-family: "Muli" !important;
}
.conversation h4 {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #798a94;
}
.conversation {
  padding: 1.5rem 1.5rem !important;
}
.conversation .ui.red.label {
  margin-left: 15px !important;
}
.selected-conversation {
  background-color: rgba(116, 139, 149, 0.25);
}

.missed-conversation h4 {
  color: red !important;
  font-weight: 600;
}

.unviewed_message {
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  background: #ffd4d2;
  border: 1px solid #e5544d;
  border-radius: 100%;
  color: #e5544d;
  width: 15px;
  padding: 0px 4px;
  margin-left: 15px;
  display: inline-block;
  height: 15px;
  margin-top: 2px;
}
