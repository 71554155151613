.assistant__list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.assistant__list li {
  position: relative;
  flex: 100% 1;
  padding: 15px;
  box-shadow: 1px 2px 3px 1px #cbc8c8;
}

.assistant__list li.not-editable {
  pointer-events: none;
  opacity: 0.6;
}

.assistant__list li textarea,
.assistant__list li .input {
  width: 100%;
  margin: 10px 0;
}

.assistant__list li textarea {
  border: 1px solid #d9d5d5;
  color: #6b6b6b;
}

.assistant__list li .outline-dropdown {
  width: 25%;
  margin-left: 30px;
}

.assistant__list .icon {
  position: absolute;
  top: 10px;
}

.assistant__list .angle {
  left: 96%;
}

.assistant__list .x {
  left: 92%;
}

.assistant__list .icon:hover {
  cursor: pointer;
  color: #9f3a38;
}

.assistant__list__label {
  display: block;
  margin: 0 0 4px 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 700;
}

/*Select only not first element and not two last*/
.assistant__list li.collapsed *:nth-child(n + 2):nth-last-child(n + 3) {
  display: none;
}

.voiceable-hide {
  display: none !important;
}

.assistant__list__input-wrapper {
  position: relative;
}

.assistant__list__input-wrapper a {
  position: absolute;
  top: 11px;
  right: 0;
  font-weight: bold;
  cursor: pointer;
  padding: 8px 12px;
  background-color: #4bb4ab;
  color: white;
  border-radius: 4px;
  font-size: 16px;
}

.assistant__list__input-wrapper a:hover {
  background-color: #00857c;
  color: white;
}
