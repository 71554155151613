.FromToDate {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.FromToDate .from,
.FromToDate .to {
  width: 120px !important;
  padding: 8px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  border-radius: 5px;
}

.FromToDate .spacer {
  width: 10px;
}

.FromToDate .button {
  width: 90px;
}

.from-to-date__calendaer {
  display: flex;
  justify-content: space-between;
  width: 370px;
}
