.supportActionButton {
  text-align: left !important;
  background: #161c30 !important;
  color: #ffffff !important;
  width: 100% !important;
  max-width: 400px !important;
  box-shadow: 0px 8px 20px rgba(38, 43, 41, 0.25) !important;
  border-radius: 10px !important;
  margin-bottom: 15px !important;
  padding: 15px !important;

  font-family: Muli !important;
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 14px !important;
}

.supportActionButton > i {
  color: #68b3ab !important;
  transform: translate(0, 5px) !important;
  font-size: 25px !important;
}
