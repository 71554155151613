.filled-voiceable_button {
  height: fit-content;
  width: 100%;
  padding: 12px 0px;
  background-color: #4bb5ab;
  color: white;
  outline: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-size: 16px;
}
.filled-voiceable_button.active,
.filled-voiceable_button:hover {
  background-color: #00857c;
}

.filled-voiceable_button:disabled,
.filled-voiceable_button[disabled] {
  background-color: rgba(0, 133, 124, 0.27);
}
