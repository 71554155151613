.work-days-field {
  display: flex;
  justify-content: start;
  padding: 0 30px;
}

.work-days-field__working-days,
.work-days-field__weekend-days {
  flex: 1 1 0;
}

.work-days-field__working-days .checkbox,
.work-days-field__weekend-days .checkbox {
  margin-bottom: 8px;
}

.work-days-field__label {
  font-size: 18px;
  margin-bottom: 2px !important;
}
