@import url("https://fonts.googleapis.com/css?family=Muli");
@font-face {
  font-family: "Muli", sans-serif;
  font-display: swap;
  src: url("https://fonts.googleapis.com/css?family=Muli");
}

body {
  background-color: rgba(242, 246, 246, 1) !important;
  font-family: "Muli" !important;
}

.App {
  text-align: center;
}

.top-layer {
  height: 20vh;
}

.sidebar-container.collapsed + #app-content {
  margin-left: 100px !important;
  width: calc(100vw - 140px) !important;
}

.sidebar-container + #app-content {
  margin-left: 275px;
  width: calc(100vw - 320px) !important;
}
#app-content {
  padding: 50px 25px 0 25px;
}
.segment.padded.raised {
  border-radius: 15px !important;
}
