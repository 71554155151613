.outline-dropdown {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  font-family: sans-serif;
}

.outline-dropdown__button {
  width: 100%;
  height: 24px;
  background-color: white;
  color: black;
  text-align: left;
  font-size: 16px;
  border: none;
  border-bottom: 1px solid #00b5ad;

  cursor: pointer;
}

.outline-dropdown__list {
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1;

  transform: translateY(-1px);
  max-height: 200px;
  overflow: auto;
  border: 1px solid #c9c9c9;
  border-top: none;
}

.outline-dropdown__list a {
  display: block;
  width: 100%;
  padding: 6px 4px;

  color: black;
  text-decoration: none;
  cursor: pointer;
}

.outline-dropdown__button:hover,
.outline-dropdown__list a:hover {
  background-color: rgba(250, 249, 249, 0.96);
}

.outline-dropdown__label {
  line-height: 1.7;
  font-size: 10px;
}
