.users {
  position: relative;
  margin-top: 70px;
  width: 100%;
  height: calc(100vh - 70px - 50px);
}

.users__inner {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
}

.users__left,
.users__right {
  width: 46%;
}

.users__right__header {
  display: flex;
  justify-content: space-between;
}

.users__right__header__left {
  display: flex;
  width: 50%;
}

.users__right__header__left > * {
  flex: 1 1 0;
}

.user__right__header__title {
  margin-top: 50px;
  margin-bottom: 20px;
  font-size: 28px;
  line-height: 1.6;
}

.users__right__action {
  display: flex;
  margin-bottom: 25px;
}

.users__right__action button:not(:last-child) {
  margin-right: 25px;
}
