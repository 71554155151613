.create-client-view {
  position: relative;
}

.create-client-view .create-button {
  display: flex;
  flex-direction: row-reverse;
  position: sticky !important;
  z-index: 1;
  top: 0;
}
