.net-promote-score__list {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
}

.net-promote-score__list li {
  display: inline-flex;
  justify-content: space-around;
  padding: 8px 0;
  width: 100%;
}

.net-promote-score__list > p {
  word-spacing: 20px;
}

.net-promote-score__list > p > span {
  border-radius: 50%;
  font-size: 24px;
  font-weight: 500;
  display: inline-block;
  line-height: 35px;
  text-align: center;
}
