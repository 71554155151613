.Agent {
  margin-top: 60px;
}

.Agent .enterer {
  height: 10px;
}

.Agent .grid-style {
  /* height: 10px; */
  padding-top: 0;
}

.Agent .ui.small.statistics .statistic > .value {
  font-size: 1.6vw !important;
}

.Agent i.massive.icon {
  font-size: 7vw;
}
