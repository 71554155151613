.stars-container {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
}

.stars-container li {
  position: relative;
  background-image: url("https://storage.voice-able.com/assistant/images/Common/star_outline.svg");
  background-repeat: no-repeat;
  width: 26px;
  height: 26px;
  background-position: center;
  background-size: 61.5%;
  background-color: #9c9c9c;
  border-radius: 50%;
  box-shadow: 0 8px 20px 0 #262b2940;
}

.stars-container li.active {
  background-color: #d6c881;
}

.stars-container li:not(:last-child) {
  margin-right: 6px;
}
