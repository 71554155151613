.voiceable-share-screen {
  z-index: 99999999;
  position: fixed;
  top: 20px;
  left: 20px;
  width: 55vw;
  max-width: calc(100vw - 460px);
  cursor: move;
}

.voiceable-share-screen.voiceable-full-width {
  top: 50% !important;
  left: 50% !important;
  transform: translateY(-50%) translateX(-50%);
  width: max-content;
}

.voiceable-full-width-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2 !important;
}

.voiceable-full-width .voiceable-share-screen-powered-by-voiceable {
  background-size: 12%;
}

.voiceable-share-screen-video {
  display: flex;
  justify-content: center;
  border: #39837c 3px solid;
  background-color: #39837c;
  border-bottom: none;
  box-sizing: border-box !important;
  width: 100%;
}

.voiceable-share-screen-video video {
  max-width: 100%;
  max-height: 100%;
}

.voiceable-full-screen .voiceable-share-screen-video {
  background-color: #07100f;
  border-bottom: #39837c 3px solid !important;
  height: 100%;
}

.voiceable-share-screen-video {
  border: #39837c 3px solid;
  border-bottom: none;
  box-sizing: border-box;
  width: 100%;
}

.voiceable-share-screen-powered-by-voiceable {
  display: block;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;
  background-size: 25%;
  /*background-image: url(https://storage.voice-able.com/assistant/images/powered_by_voiceable_small.svg);*/
  position: absolute;
  height: 2vw;
  bottom: -2vw;
  width: 100%;
  background-repeat: no-repeat;
  background-color: #161c30;
  background-position: center;
  left: 0;
}

#voiceable-share-screen-id.voiceable-full-screen-simple {
  left: 0 !important;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2147483637;
  transform-origin: left bottom;
  max-width: 100vw;
  background-color: #07100f;
  position: fixed;
}

#voiceable-share-screen-id.voiceable-full-screen-simple.voiceable-desktop {
  top: 0 !important;
}

.voiceable-full-screen-simple .voiceable-share-screen-video {
  height: 100%;
  border: #39837c 3px solid;
  background-color: #07100f;
}

.voiceable-full-screen-simple .voiceable-share-screen-powered-by-voiceable {
  display: none;
}

.voiceable-full-screen-simple .voiceable-full-screen-button {
  padding: 26px !important;
  background-size: 26px !important;
  background-image: url(https://storage.voice-able.com/assistant/images/fullscreen-white.svg);
  top: 20px;
  right: 20px;
}

.voiceable-full-screen-simple .voiceable-full-screen-button img {
  width: 35px !important;
  transition-duration: 100ms;
}

@media screen and (max-width: 968px) {
  .voiceable-share-screen.voiceable-full-screen-simple {
    transform: unset;
  }
}

@media screen and (max-width: 968px) and (orientation: portrait) {
  .voiceable-share-screen {
    width: 90vw;
    max-width: 90vw;
  }

  .voiceable-share-screen-powered-by-voiceable {
    background-size: 12% !important;
  }
}

@media screen and (orientation: portrait) {
  #voiceable-share-screen-id.voiceable-full-screen-simple.voiceable-mobile {
    transform: rotate(90deg);
    top: -100vw;
    height: 100vw;
    width: 100vh;
    max-width: 100vh;
  }

  .voiceable-full-screen-simple .voiceable-full-screen-button {
    top: 20px;
    left: 20px !important;
    right: unset !important;
    padding: 30px !important;
    background-size: 35px !important;
  }
}

.voiceable-full-screen-button {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px !important;
  cursor: pointer;
  background-color: #39837c;
  border-radius: 50%;
  transition-duration: 100ms;
  border: none !important;
  z-index: 2;
  right: 10px;
  top: 10px;
  background-image: url(https://storage.voice-able.com/assistant/images/fullscreen-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px !important;
}

.voiceable-full-screen .voiceable-full-screen-button {
  padding: 26px !important;
  background-size: 26px !important;
  background-image: url(https://storage.voice-able.com/assistant/images/fullscreen-exit-white.svg);
}

.voiceable-full-screen .voiceable-full-screen-button img {
  width: 35px !important;
  transition-duration: 100ms;
}
