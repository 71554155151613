.attendance__top {
  height: 55px;
  display: flex;
  justify-content: space-between;
}

.multiselect-option {
  height: 20px;
  display: flex;
  align-content: center;
}

.multiselect-option .option-text {
  padding-left: 10px;
  padding-top: 2px;
  font-weight: 400;
}

.attendance__toolbar {
  display: flex;
}
