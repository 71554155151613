#groups-list-container * {
  text-align: left;
}

#groups-list-container i,
#cancel-group-selection {
  color: #68b3ab;
}

#groups-list-container .item {
  margin: 10px 0px;
}

#cancel-group-selection {
  display: block;
  font-size: 16px;
  cursor: pointer;
  margin-top: 30px;
}

#groups-list-container {
  border-radius: 12px;
}

.list-of-agents {
  overflow-y: auto;
  max-height: 25vh;
}

.list-of-agents a {
  width: fit-content !important;
}

.list-of-agents .on-call i,
.list-of-agents .on-call a {
  color: #a10a0a !important;
  font-weight: 500;
}

.list-of-agents .available i,
.list-of-agents .available a {
  color: green !important;
  opacity: 0.8 !important;
}

.list-of-agents .not-available a,
.list-of-agents .not-available i {
  color: darkred !important;
  font-weight: 500;
}

.list-of-agents_group-name {
  cursor: pointer;
}
