.input-indicator-wrapper {
  width: 100%;
}

.indicator {
  width: calc(10% - 10px);
  height: 10px;
  display: inline-block;
  margin: 5px;
}
