.outline-dropdown-filter {
  outline: none;
  width: 100%;
  border: 1px solid #c9c9c9;
  background-color: rgba(250, 249, 249, 0.96);
}

.outline-dropdown-filter:focus + .outline-dropdown__list {
  display: block !important;
}
