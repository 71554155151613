.CallEndedForm {
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: stretch;
  background-color: #dce2e4;
  padding-left: 265px;
}

.CallEndedForm .end-form-section {
  min-height: 100vh;
  position: relative;
  padding-top: 100px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CallEndedForm .end-form-section:first-child {
  border-right: 5px solid #fff;
}

.CallEndedForm .end-form-section h1 {
  padding: 0 25px;
}

.CallEndedForm button {
  border: none;
  outline: none;
}

.CallEndedForm .enterer {
  height: 10px;
}

.CallEndedForm .bigger-enterer {
  height: 20px;
}

.CallEndedForm .button {
  font-size: 16px;
  width: 200px;
  padding: 10px;
  border: 1px solid #39837c;
  background-color: transparent;
  color: #39837c;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.CallEndedForm .submit-button {
  font-size: 16px;
  width: 200px;
  padding: 10px;
  margin-top: 30px;
  border: 1px solid rgb(22, 28, 48);
  background-color: rgb(22, 28, 48);
  color: white;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.CallEndedForm .number-field {
  width: 50%;
}

.CallEndedForm .text-one-line-field {
  width: 70%;
}

.CallEndedForm .submit-button:hover {
  border: 1px solid rgb(12, 16, 27);
  background-color: rgb(12, 16, 27);
  color: white;
  transition: background-color 0.1s linear;
}

.CallEndedForm .pushed {
  border: 1px solid #39837c;
  background-color: #39837c;
  color: white;
}

.CallEndedForm .button:hover {
  border: 1px solid #39837c;
  background-color: #39837c;
  color: white;
  transition: background-color 0.1s linear;
}

.CallEndedForm h1 {
  font-size: 25px;
  line-height: 1em;
  font-weight: 500;
}

.CallEndedForm textarea {
  width: 70%;
  height: 80px;
  border-radius: 2px;
  border: 1px;
  padding: 10px;
}

.CallEndedForm textarea:focus {
  border: 2px solid #bbb2b2;
  border-radius: 2px;
}

.CallEndedForm .textarea-error {
  border: 1px solid #e0b4b4;
  border-radius: 0.28571429rem;
  background-color: #fff6f6;
}

.CallEndedForm .ui.fluid.multiple.search.selection.dropdown {
  margin: 0 !important;
}

.CallEndedForm .ui.dropdown:not(.button) > .default.text {
  color: #7e7577;
}

.CallEndedForm .ui.dropdown.error > .menu > .item {
  color: rgba(0, 0, 0, 0.95);
}

.CallEndedForm textarea:disabled {
  background-color: #eceff0;
}

.CallEndedForm textarea:disabled::placeholder {
  color: #b8b8ba;
}

.CallEndedForm input::placeholder {
  color: #7e7577 !important;
}

.CallEndedForm input:disabled::placeholder {
  color: #b8b8ba;
}

.CallEndedForm .custom-field-block {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.CallEndedForm .custom-field-block .error-message {
  height: 15px;
  padding-left: 10px;
  font-size: 14px;
  color: red;
  width: 70%;
}

.CallEndedForm .custom-field-block .number-field + .error-message {
  width: 50%;
}

@media (max-width: 768px) {
  .CallEndedForm {
    padding: 0;
    flex-direction: column;
  }

  .CallEndedForm .end-form-section:first-child {
    border-right: none;
  }

  .CallEndedForm .end-form-section {
    width: 100vw;
    min-height: unset;
    padding: 0;
  }
}
