.field-button {
  width: 100%;
}

.field-button:not(:last-child) {
  margin-right: 7px;
}

.field-button > label {
  display: block;
  margin: 0 0 4px 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
  font-weight: 700;
}

.field-button__input {
  width: 100%;
  padding: 10px;
  outline: none;
  border: 1px solid rgba(171, 171, 171, 0.52);
  border-radius: 3px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
