.day-field__wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-bottom: 20px;
}

.day-field__time-wrapper {
  display: flex;
  align-items: center;
}

.day-field__time-wrapper > i {
  height: 100% !important;
  padding: 0;
  margin: 0 0 30px !important;
}

.day-field__time-wrapper.disabled {
  pointer-events: none;
  opacity: 0.6;
}

.day-field__wrapper label {
  margin-bottom: 4px;
}
