.outline-input__input:focus {
  outline: none !important;
}

.outline-input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.outline-input__input {
  height: 24px;
  border: none;
  border-bottom: 1px solid #00b5ad;
}

.outline-input__label {
  line-height: 1.7;
  font-size: 10px;
}
