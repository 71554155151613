.modal-list-editor {
  max-height: 40vh;
  min-height: 20vh;
  overflow-y: auto;
}

.modal-list-editor .item {
  cursor: default !important;
  display: flex !important;
  align-items: center;
  height: 40px;
  gap: 10px;
}

.modal-list-editor .header {
  font-size: 16px;
}

.modal-list-editor .content {
  flex: 1;
}

.modal-list-editor .icon {
  font-size: 18px;
  cursor: pointer;
}

.modal-editor-actions {
  display: flex;
}

.modal-editor-reset-section {
  flex: 1;
  display: flex;
}

.modal-list-add-item-section {
  height: 80px;
}
.modal-list-add-item-section .input {
  width: 400px;
}

.modal-list-add-item-input-block {
  display: flex;
  gap: 15px;
}
