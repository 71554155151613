.postfixes-field > * {
  margin-bottom: 12px;
}

.postfixes-field__wrapper {
  position: relative;
}

.postfixes-field__wrapper > .icon {
  position: absolute;
  left: 96%;
  top: 3px;
}

.postfixes-field__wrapper > .icon:hover {
  cursor: pointer;
  color: #9f3a38;
}
