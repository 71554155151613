.time-picker {
  display: flex;
  border-radius: 18px;
  justify-content: space-around;
  flex-direction: column;
}

.time-picker__time {
  display: flex;
}

.time-picker__wrapper {
  display: flex;
}

.time-picker__field {
  display: flex;
  flex-direction: column;
}

.time-picker__field input {
  width: 60px;
  height: calc(60px / 1.3);
  font-size: 25px;
  box-sizing: border-box;
  border: unset;
  background-color: #eeeeee;
  border-radius: 4px;
  text-align: center;
}

.time-picker__field label {
  font-size: 12px;
}

.time-picker__field input:focus-visible,
.time-picker__field input:focus {
  border: unset;
  outline: unset;
  background-color: #80e8dd;
}

.time-picker__divider {
  height: 100%;
  margin: 0 2px;
}

.time-picker__divider::after {
  content: ":";
  font-size: 35px;
  line-height: 40px;
}
