#invite {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 400px;
  float: right;
  margin: 0;
  z-index: 9;
  box-shadow: none !important;
}
#invite .header {
  margin-bottom: 20px;
}
#invite {
  border: 2px solid #00b5ad;
}
#accept-invite {
  color: #00b5ad;
  box-shadow: 0 0 0 2px #00b5ad inset !important;
}
#accept-invite:hover {
  background-color: #00b5ad !important;
  color: #ffffff;
}
