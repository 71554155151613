.multi-selector {
  width: 100%;
  min-height: 37px;
  position: relative;
  padding: 10px 5px;
  display: inline-block;
  border-bottom: 1px gray solid;
}

.multi-selector:hover {
  border-bottom: 1px #4a72bd solid;
}

.multi-selector__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.multi-selector__content > span {
  background-color: #e0e0e0;
  color: black;
  padding: 7px 15px;
  border-radius: 16px;
  margin: 2px;
}

.multi-selector__content p {
  padding: 0;
  margin: 20px 0 0;
  color: #c2c2c2;
}

.multi-selector__list {
  position: absolute;
  display: none;
  flex-direction: column;
  max-height: 224px;
  left: -1px;
  top: 100%;
  overflow-y: scroll;
  width: 100%;
  background-color: white;
  z-index: 1000;

  border-radius: 3px;
  box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
    0 3px 14px 2px rgb(0 0 0 / 12%);
}

.multi-selector__list span {
  width: 100%;
  padding: 10px;
}

.multi-selector__list > span:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.08) !important;
}

.multi-selector__arrow {
  cursor: pointer;
  position: absolute;
  width: 24px;
  height: 12px;

  transform: translateY(-50%);
  top: 50%;
  right: 5px;
}

.multi-selector__arrow:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid rgba(5, 5, 5, 0.24);
}
