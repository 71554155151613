.assistant {
  min-height: 110vh;
  width: 80% !important;
}

.assistant__submit {
  display: flex;
  flex-direction: row-reverse;
  position: sticky !important;
  z-index: 1;
  top: 0;
  color: #cbc8c8 !important;
}

.assistant__submit:hover {
  color: #00857c !important;
}
