.create-client__field {
  font-weight: 600;
  font-size: 16px;
  padding: 10px 0;
  border-bottom: 1px solid #d5d5d5;
}

.create-client__field > .create-client__field {
  margin-left: 20px;
  margin-bottom: 5px;
  border-bottom: unset;
  padding: unset;
  font-weight: 400;
  font-size: 0.9em;
}

.create-client__field.disabled {
  pointer-events: none;
  opacity: 0.4;
}
