.mobile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  position: relative;
}

.logo-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  background: url(../../../img/logo.png) no-repeat center;
  height: 40px;
  width: 100%;
  margin-bottom: 4px;
}

.client-name {
  color: #9a9a9a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  font-size: 20px;
  margin-bottom: 4px;
}

.calls {
  background-color: #dce2e4;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
}

.chat {
  width: 100%;
  height: 100%;
  padding: 0 10px 20px;
  box-sizing: border-box;
}

.calls .navigation {
  width: 90%;
  height: 20%;
  display: flex;
  flex-direction: column;
}

.calls .navigation h3 {
  text-align: "center";
  margin-bottom: "30px";
  color: "#4bb4aa";
}

.call-actions {
  margin-bottom: 30px;
}

.controls {
  position: fixed;
  width: 100%;
  height: 50px;
  background-color: #fff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  padding: 4px;
  box-sizing: border-box;
  bottom: 0;
}

.controls span {
  border-radius: 10px;
  font-size: 15px;
  padding: 5px;
}

.controls .available {
  color: rgb(0, 121, 0);
  border: 1px solid rgb(0, 121, 0);
}

.controls .unavailable {
  color: rgb(255, 105, 94);
  border: 1px solid rgb(255, 105, 94);
}

.navigation_heading {
  text-align: center;
  margin-bottom: 30px;
  color: #4bb4aa;
  padding-top: 10px;
}
