#incomming-call-container {
  margin-top: 15vh;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
}

#incomming-call-container > img {
  width: 20%;
  min-width: 150px;
  margin-bottom: 15px;
}

#incoming-call-header {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;
  display: block;
  margin-bottom: 75px;
}

.answer-call-btn {
  background: #68b3ab;
  box-shadow: 0px 8px 20px rgba(38, 43, 41, 0.25);
  border-radius: 14px;
  padding: 20px;
  padding-left: 75px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 120.9%;
  text-transform: uppercase;
  color: white;
  text-align: left;
  position: relative;
  margin-bottom: 20px;
  height: 60px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.leave-btn {
  background: #f17461;
}

.leave-btn img {
  transform: rotate(124deg);
  margin-top: 1px;
}

.answer-call-btn img {
  position: absolute;
  height: 100%;
  width: 30px;
  left: 20px;
  top: 0;
}

#incoming-call-decline-btn {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #e5544d;
  cursor: pointer;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
}

@media only screen and (max-width: 1100px) {
  #incomming-call-container {
    width: 75%;
  }
}

@media (max-width: 768px) {
  #incomming-call-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    align-items: center;
  }

  #incomming-call-container > img {
    width: 20%;
    min-width: 120px;
    margin-bottom: 15px;
  }

  #incoming-call-header {
    margin-bottom: 50px;
  }

  .answer-call-btn {
    width: 290px;
  }

  #incoming-call-decline-btn {
    margin: 0;
    margin-top: 30px;
  }
}
