.pagination {
  display: flex;
  justify-content: center;
}

.pagination__list {
  display: flex;
  flex-wrap: wrap;
}

.pagination__list li {
  user-select: none;
  margin: 0 2px;
  height: 30px;
  min-width: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
}

.pagination__item {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 2px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(155, 155, 155, 0.2);
  cursor: pointer;
}

.pagination__item__active,
.pagination__list .pagination__item:hover {
  background-color: #4bb5ab;
  color: white;
}
