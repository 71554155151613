.simple-dropdown {
  width: 100%;
  margin-bottom: 20px;
}

.simple-dropdown__title {
  width: 100%;
  padding: 12px 16px;
  background-color: white;
  color: black;
  text-align: left;
  font-size: 16px;
  border: 1px solid #e8dede;
  cursor: pointer;
}

.simple-dropdown {
  position: relative;
  display: inline-block;
}

.simple-dropdown__list {
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 1;
  border: 1px solid #e8dede;
  transform: translateY(-1px);
}

.simple-dropdown__list a {
  display: block;
  width: 100%;
  padding: 12px 5px;

  color: black;
  text-decoration: none;
  cursor: pointer;
}

.simple-dropdown__title:hover,
.simple-dropdown__list a:hover {
  background-color: rgba(250, 249, 249, 0.96);
}
