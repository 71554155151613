.participant h2 {
  position: absolute;
  color: white;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  margin: 0px;
  text-transform: capitalize;
  text-align: center;
}

.participant:has(video) h2 {
  bottom: 40px;
  top: unset;
}

.local-participant:has(video) h2 {
  display: none;
}

.hidden {
  display: none;
}

.participant__screen-share video {
  height: 100%;
  width: 100%;
}
.participant__video video {
  width: 100%;
  height: 300px;
}
