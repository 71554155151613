.support-audio {
  width: 350px;
}

.support-audio {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.support-audio__item {
  display: flex;
  align-items: center;
  width: 100%;
}

.support-audio-checkbox-item {
  width: 100%;
  padding: 10px 0px 0px 10px;
}

.audio-settings-close-icon {
  cursor: pointer;
}

.video-settings {
  margin-top: 10px;
  margin-left: 10px;
}

.video-settings__background-image {
  display: flex;
  justify-content: space-around;
}

.video-settings__background-image li img {
  width: 50px;
  height: 50px;
  cursor: pointer;
  background-size: cover;
}

.video-settings__background-image li.selected img {
  opacity: 0.6;
}

.checkbox {
  margin-right: 10px;
}

.video-settings__background-image .remove-image:hover {
  color: red;
  cursor: pointer;
}
