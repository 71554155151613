.share-screen-container button.red {
  background-color: #e5544d !important;
}

.share-screen-container button.red:hover {
  background-color: #d94d47 !important;
}

.share-screen-container button.grey {
  background-color: #9c9c9c !important;
  border: 2px solid #6c6c6c;
}
