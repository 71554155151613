@import url("https://fonts.googleapis.com/css?family=Muli");
@font-face {
  font-family: "Muli";
  font-display: swap;
  src: url("https://fonts.googleapis.com/css?family=Muli");
}

.sidebar-container > .Sidebar {
  display: flex;
  flex-direction: column;
  background: linear-gradient(rgba(0, 133, 124, 1), rgba(75, 180, 170, 1));
  height: calc(100vh - 75px) !important; /* calc( 100vh - Logo Height) */
  border-radius: 0pt 20pt 0pt 0pt;
  overflow: hidden;
}

.Sidebar > .top {
  display: flex;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: 10pt;
  border-bottom: 1px solid rgba(75, 180, 170, 1);
  padding: 1.5rem 1rem;
  cursor: pointer;
}

.Sidebar > .top > .client-info > .client-name {
  margin: 0px;
  width: 180px;
  color: white;
  font-size: 14pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.Sidebar > .top > .client-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Sidebar > .top > .client-info > .client-version {
  font-size: 9pt;
  display: none;
}

.Sidebar > .menu-section .menu > .active.item {
  background-color: #036b64 !important;
}

.Sidebar > .menu-section .menu > .item {
  padding: 1rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.5rem;
}
.Sidebar > .menu-section .menu > .item > a {
  font-size: 12pt !important;
  display: block;
}

.Sidebar-greyout {
  color: lightslategrey !important;
}

.sidebar-icon {
  height: 1.5rem !important;
  margin-top: -0.75rem !important;
  width: 1.5rem !important;
}

.logout {
  font-size: 1.3rem;
}
.sidebar-container {
  text-align: left;
  width: 235px !important;
  display: inline-block;
  position: fixed;
  /* overflow: hidden; */
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
  top: 0px;
}
.sidebar-placeholder {
  width: 275px !important;
  height: 100vh;
  display: inline-block;
}
.sidebar-placeholder.collapsed {
  width: 100px !important;
}

.Sidebar > .menu-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
}

.Sidebar > .menu-section .menu {
  border: none !important;
  margin-top: 2.5rem !important;
}
.Sidebar > .menu-section .menu > .item .icon {
  float: left !important;
  margin-right: 10px !important;
  margin-left: 0px !important;
}

.Sidebar > .menu-section > .bottom-menu {
  flex: 1;
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 3.8rem;
}

.Sidebar > .menu-section > .bottom-menu > .menu {
  margin-top: 0 !important;
}

.Sidebar {
  height: 90vh;
}

.sidebar-container #logo-container {
  padding: 1.7rem 1rem;
  min-height: 75px;
}
#logo-container img {
  min-width: 150px !important;
}
#logo-container.collapsed {
  display: block;
  width: 100vw;
  background-color: rgba(242, 246, 246, 1) !important;
  z-index: 100000;
}
.sidebar-container.collapsed {
  width: 60px !important;
  z-index: 10;
}

.Sidebar .height-for-icon {
  height: 20px;
}
