.ToDateDropdown {
  display: flex;
  align-items: center;
}

.ToDateDropdown .button {
  width: 90px;
}

.ToDateDropdown .ui.selection.dropdown {
  min-width: 166px !important;
}

.to-date-dropdown__calendar {
  display: flex;
  justify-content: space-between;
  width: 370px;
  margin-right: 10px;
}
