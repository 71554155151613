.share-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.video-screen-wrapper video {
  max-width: 100%;
  border: #39837c 4px solid;
}
