.text-button-chat-container {
  display: flex;
  justify-content: center;
}

.text-button-chat-container button.red {
  background-color: #e5544d !important;
}

.text-button-chat-container button.red:hover {
  background-color: #d94d47 !important;
}

.chat-block {
  display: flex;
  flex-direction: column;
  justify-content: end;
  position: fixed;
  z-index: 3;
  left: auto;
  bottom: 10px;
  top: 375px;
  max-height: 400px;
  width: 330px;
  padding: 7px 10px 30px;
  border-radius: 14px;
  gap: 5px;
  box-shadow: 0 8px 20px rgba(38, 43, 41, 0.25);
  background-color: #161c30;
}

.chat-input-section {
  display: flex;
  padding: 0px 10px 0px;
}

.chat-messages-section {
  padding: 20px 10px 5px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.chat-messages-section::-webkit-scrollbar {
  width: 10px;
}

.chat-messages-section::-webkit-scrollbar-thumb {
  background-color: #265450 !important;
}

.chat-input-section textarea {
  width: 100%;
  height: 20px;
  resize: none !important;
  overflow: hidden;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0;
  font-family: "Muli";
  font-size: 14px;
  min-height: 20px;
  color: #dce2e4;
  box-sizing: border-box !important;
  background-color: #464a55;
  border-color: rgba(0, 0, 0, 0) !important;
}

.chat-input-field-wrapper {
  padding: 0.7em 0.9em;
  background-color: #464a55;
  border-radius: 14px;
  flex: 1;
  display: flex;
}

.chat-input-section textarea:focus {
  outline: none !important;
}

.chat-input-section textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.chat-send-button-block {
  display: flex;
  flex-direction: column-reverse;
  padding-left: 10px;
  padding-bottom: 3px;
  cursor: pointer;
}

.chat-send-button:hover path {
  transition-duration: 200ms;
  fill: #449b92 !important;
}

.chat-message-container {
  width: 100%;
  margin-top: 10px;
  display: flex;
}

.chat-message-container.agent {
  flex-direction: row-reverse;
}

.chat-message-container.client {
  flex-direction: row;
}

.chat-message {
  padding: 12px 16px;
  background: #464a55;
  border-radius: 10px;
  color: #ffffff;
  font-family: "Muli";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px !important;
  white-space: pre-wrap;
  word-wrap: break-word;
  max-width: 90%;
}

.chat-message.agent {
  background: #39837c;
  border-bottom-right-radius: 0;
}

.chat-message.client {
  background: #464a55;
  border-top-left-radius: 0;
}

.chat-disabled {
  display: none;
}

.message-not-valid {
  color: red;
  font-weight: 600;
  position: absolute;
  bottom: 4px;
  left: 20px;
}
