@import url("https://fonts.googleapis.com/css?family=Muli");
@font-face {
  font-family: "Muli", sans-serif;
  font-display: swap;
  src: url("https://fonts.googleapis.com/css?family=Muli");
}
.loader-text {
  font-family: "Muli", sans-serif !important;
  font-size: 12pt;
}
