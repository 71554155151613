.tags {
  width: 100%;
  margin-top: 5px;
}

p.tags__label {
  margin-bottom: 10px;
}

.tags > div > input {
  min-width: 202px;
}

.tags-line {
  height: 3px;
  width: 100%;
  display: block;
  margin-top: 4px;
  background-color: #e8e8e8;
}

.tags:hover .tags-line {
  background-color: #e0e0e0;
}
