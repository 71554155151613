li {
  text-decoration: none;
  list-style-type: none;
}

p:first-child,
p {
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;
}

.toggle-list {
  height: calc(100% - 160px);
}

.toggle-list__header {
  display: flex;
  flex-wrap: wrap;
}

.toggle-list__header > li {
  margin: 2px;
  padding: 5px 10px;
  background-color: rgba(171, 171, 171, 0.52);
  border-radius: 16px;
}

.toggle-list__header__input {
  display: flex;
  align-self: center;
  margin-left: 8px;
  margin-top: 2px;
}

.toggle-list__header__input > input {
  width: 110px !important;
}

.toggle-list__list {
  overflow-y: scroll;
}

.toggle-list__list li {
  display: flex;
  border: 1px solid rgba(171, 171, 171, 0.52);
  padding: 10px;
  cursor: pointer;
  height: 70px;
  flex-direction: column;
  justify-content: center;
}

.toggle-list__list li.active {
  background-color: rgba(171, 171, 171, 0.52);
}

.toggle-list__list li:hover {
  background-color: rgba(171, 171, 171, 0.32);
}

.toggle-list__list li.active .toggle-list__list__title,
.toggle-list__list li:hover .toggle-list__list__title {
  color: white;
}

.toggle-list__list li.active .toggle-list__list__description,
.toggle-list__list li:hover .toggle-list__list__description {
  color: rgba(255, 255, 255, 0.69);
}

.toggle-list__list__title {
  font-size: 16px;
  color: black;
}

.toggle-list__list__description {
  font-size: 14px;
  color: gray;
}
