.ModalWelcomePage {
}

.ModalWelcomePage .p-modal {
  font-size: 30px;
  font-weight: 400;
  text-align: center;
  padding: 20px;
}

.ModalWelcomePage .button-proceed {
  background-color: rgba(0, 181, 173, 1);
  color: white;
  transition: all 0.1s ease-out;
}

.ModalWelcomePage .button-proceed:hover {
  background-color: rgba(0, 181, 173, 0.7);
  color: white;
}

.ModalWelcomePage .button-proceed:focus {
  background-color: rgba(0, 181, 173, 1);
  color: white;
}
