.TeamComparison {
  width: 100%;
  margin-top: 75px;
}

.TeamComparison .link {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.TeamComparison .tooltip {
  position: relative;
  cursor: pointer !important;
}

.TeamComparison .tooltip .tooltiptext {
  visibility: hidden;
  opacity: 0;
  background-color: teal;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 100%;
  transition: visibility 0.1s linear, opacity 0.1s linear;
}

.TeamComparison .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: teal transparent transparent transparent;
}

.TeamComparison .tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.TeamComparison .align-same-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.TeamComparison .segment-style {
}

@media only screen and (max-width: 1600px) {
  .TeamComparison .segment-style {
    overflow-y: hidden;
    overflow-x: auto;
  }

  .TeamComparison .flipped {
    transform: rotateX(180deg);
    -ms-transform: rotateX(180deg); /* IE 9 */
    -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  }
}
