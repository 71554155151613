.pop-up {
  position: fixed;
  top: -20px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  border-radius: 14px;
  padding: 50px 30px 30px 30px;
  box-shadow: 0px 0px 12px 0px rgb(171 171 171);
  background-color: white;
  z-index: 1;
}

.pop-up__text {
  font-size: 20px;
}

.pop-up__close {
  cursor: pointer;
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: white;
  border: none;
  color: #717171;
  font-size: 18px;
  font-weight: 700;
}

.pop-up__close:hover {
  background-color: whitesmoke;
}
