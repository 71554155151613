#call-container {
  margin-top: 25px;
}
#local-agent {
  border-radius: 14px;
}

#call-container.multigroup #local-agent {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  margin-top: 0px;
}

.rounded-top {
  border-top-left-radius: 14px;
  border-top-right-radius: 14px;
}

#local-agent,
.remote-agent {
  overflow: hidden;
  background: #39837c;
  box-shadow: 0 8px 20px rgba(38, 43, 41, 0.25);
  width: 400px;
  height: 300px;
  margin: 15px auto auto;
  position: relative;
}

#call-buttons-container {
  position: absolute;
  z-index: 2;
  width: 100%;
  bottom: 0;
}

.image_button {
  height: 50px;
  display: inline-block;
  position: initial;
  width: 50px;
  border-radius: 100%;
  object-fit: cover;
  cursor: pointer;
  margin-right: 15px;
}

#customer label#connection-text {
  font-size: 20px;
}
#customer label#indicator {
  background-color: rgba(75, 223, 209, 1) !important;
  border-color: rgba(75, 223, 209, 1) !important;
  min-width: 0;
  min-height: 0;
  overflow: hidden;
  width: 20px;
  height: 20px;
  vertical-align: sub;
  border-radius: 500rem;
  display: inline-block;
}
#customer {
  margin: 15px 0px;
}
#customer h2 {
  display: none;
}
.customer_connection_status_header {
  color: white;
  text-align: center;
  position: absolute;
  z-index: 2;
  top: 60%;
  transform: translate(0px, -50%);
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
}

#cancel-invite {
  display: block;
  font-size: 16px;
  cursor: pointer;
  margin-top: 30px;
  color: #68b3ab;
}

@media (max-width: 768px) {
  #call-actions-container {
    margin: 0;
    width: 70%;
    display: flex;
    flex-direction: column;
  }

  #call-buttons-container {
    display: flex;
    justify-content: space-evenly;
  }

  .image_button {
    margin: 0;
  }
}

.open-token-call_invite {
  width: 400px;
  margin: auto;
}
