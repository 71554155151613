.input {
  background-color: transparent;
  border: none;
  outline: none;
  color: black;
}

.input.bottom-line {
  border-bottom: 1px solid rgba(171, 171, 171, 0.52);
}

.input.not-valid {
  background-color: #fff6f6;
  border-color: #e0b4b4;
  color: #9f3a38;
}

.input.small::placeholder,
.input.small {
  font-size: 12px;
}

.input.medium::placeholder,
.input.medium {
  font-size: 16px;
}

.input.large::placeholder {
  font-size: 18px;
}

.input.large {
  font-size: 20px;
}

.input::placeholder {
  color: rgba(171, 171, 171, 0.52);
}
