.date-calendar input {
  width: 120px;
  padding: 8px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  outline: 0;
  border-radius: 5px;
  margin-left: 1px;
}

.date-calendar label {
  margin-right: 10px;
}
