.user-table {
  height: 100%;
}

.user-table__list {
  padding: 0;
  margin-top: 26px;
  height: calc(100% - 26px - 60px);
  overflow-y: scroll;
}

.user-table__list__item {
  display: flex;
  height: 70px;
  align-items: center;
  border: 1px solid rgba(171, 171, 171, 0.52);
  padding: 10px;
  cursor: pointer;
}

.user-table__list__item.active,
.user-table__list__item:hover {
  background-color: rgba(171, 171, 171, 0.52);
  color: white;
}

.user-table__list__item + .users__left__list__item {
  margin: -1px 0;
}

.user-table__list__item__img {
  height: 50px;
  width: 50px;

  border-radius: 50%;
}

.user-table__list__item__roles {
  font-size: 10px;
  color: #898989;
}

.user-table__search__btn,
.user-table__btn {
  display: inline-block;
  width: 25%;
}

.user-table__search__btn {
  margin-left: 20px;
  height: 36px;
}

.user-table__search__btn__input {
  width: fit-content;
  background-color: transparent;
  border: none;
  outline: none;
  border-bottom: 1px solid rgba(171, 171, 171, 0.52);

  color: black;
  font-size: 20px;
}

.user-table__search__btn__input::placeholder {
  color: rgba(171, 171, 171, 0.52);
  font-size: 18px;
}

.user-table__list__item__info {
  margin: 0 10px;
}

.user-table__list__item__full-name {
  font-size: 16px;
}
