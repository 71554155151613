#chat,
#support-control-pane {
  width: calc((100vw - 265px) / 2) !important;
  display: inline-table;
}

#support-control-pane {
  background: #dce2e4;
  height: 100vh;
  padding: 2em 1.4em;
  margin-left: 265px;
  margin-right: 5px;
}

#screen-share-block {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
}

#chat-button-block {
  margin-top: 10px;
}

#chat {
  background: #dce2e4;
  text-align: center;
  height: 100vh;
  top: 0;
  position: absolute;
  float: right;
}
#logo-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

#console-client-info > .client-name {
  margin: 0px;
  width: 250px;
  height: 250px;
  font-size: 22pt;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #9a9a9a;
  text-align: center;
}

.simple-dropdown__list a {
  font-size: 13pt;
}

#console-client-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

#console-client-info > .client-version {
  font-size: 9pt;
  display: none;
}

#conversations-list-container {
  text-align: left;
  display: inline-block;
  position: fixed;
  background: white;
  width: 265px;
  height: 100%;
}
#conversations-list-container .back {
  color: rgba(75, 180, 170, 1);
  font-family: "Muli";
  font-size: 12pt;
  padding: 2.2rem 0rem;
  display: block;
}
#view-placeholder {
  margin: 0 auto;
  margin-top: calc(75px - 2em);
  max-width: 500px;
}

#support-control-pane iframe {
  height: 100%;
  border-radius: 10px;
  border: none;
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 85%;
  max-width: 400px;
  margin-bottom: 15px;
}

.videocall .OT_publisher {
  height: 35vh !important;
}

.videocall {
  height: fit-content;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#view-placeholder.video {
  height: 50vh;
  margin-top: 0px;
}

#view-placeholder.video iframe {
  width: 60%;
}

.purple_frame {
  border: 5px solid #635cf6 !important;
}

.purple_font {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 120.9%;
  /* or 24px */

  text-align: center;

  /* royal blue */

  color: #645cf6;
}

#call_over_message img {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
#call_over_message h3 {
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;
  text-align: center;

  /* black */

  color: #161c30;
}

#call_over_message {
  margin-top: 15vh;
}

#current_page_nav {
  width: max-content !important;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

#full-screen-btn {
  display: none;
}

#invite_agent {
  margin-top: 15px;
}

#signout {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  left: 0;
  bottom: 0;
  background: inherit;
  padding: 0 15px 15px;
  height: 55px;
}

#signout .available {
  color: rgb(0, 121, 0);
  border: 1px solid rgb(0, 121, 0);
}

#signout .unavailable {
  color: rgb(255, 105, 94);
  border: 1px solid rgb(255, 105, 94);
}

#signout .available,
#signout .unavailable {
  width: 50%;
  text-align: center;
  cursor: default;
  border-radius: 10px;
  font-size: 15px;
  padding: 5px;
}

#console-settings-btn {
  margin-left: 3px;
  margin-right: 18px;
  color: #6c6c6c;
  display: block;
  cursor: pointer;
}

#console-settings {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  left: 0;
  bottom: 55px;
  background: inherit;
  padding: 5px 15px 0;
  padding: 5px 15px 0;
  height: 50px;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ .coloring.teal:before {
  background: #00b5ad !important;
}

@media only screen {
  #call-actions-container {
    margin-top: 15vh;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  #full-screen-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    display: block;
  }
}

#call-actions-container .answer-call-btn {
  width: 400px;
}

@media only screen and (max-width: 1100px) {
  #support-control-pane {
    padding: 2em 0em;
  }
}

@media (max-width: 768px) {
  #call-actions-container {
    margin: 0;
    width: 70%;
  }
}
