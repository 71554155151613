.settings {
  margin-top: 52px !important;
  padding-bottom: 40px;
}

.settings-title {
  font-size: 18px;
}

.settings-inputs-container {
  width: 100%;
  display: grid;
  grid-template-columns: 325px 1fr;
  padding-left: 15px;
  gap: 10px;
  margin-bottom: 10px;
}

.settings-checkbox-block {
  display: flex;
  align-items: center;
}

.settings-editbox-block .input {
  width: 300px;
}

.settings-editbox-block-multiple {
  width: 300px;
  display: flex;
  gap: 15px;
}

.settings-editbox-block textarea {
  width: 300px;
  height: 30px;
  resize: none !important;
  overflow: hidden;
  border: 1px solid rgba(34, 36, 38, 0.15);
  padding: 0.7em 0.9em;
  min-height: 38px;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  color: rgba(0, 0, 0, 0.87);
  padding-right: calc(0.4em + 10px);
  box-sizing: border-box !important;
}

.settings-editbox-block textarea:focus {
  border-color: #87ceeb !important;
  outline: none !important;
}

.settings-editbox-block textarea.big-field {
  padding-right: 0.4em;
  max-height: 225px;
  overflow-y: auto;
}

.settings-header {
  margin: 5px 0 10px;
}

.settings-subheader {
  font-size: 14px;
  margin: 0;
  margin-left: 15px;
  margin-top: 15px;
}

.settings-header.config-deep-0 {
  margin-top: 15px;
}

.config-deep-1 {
  margin-left: 15px !important;
}

.config-deep-2 {
  margin-left: 30px !important;
}

.config-deep-3 {
  margin-left: 45px !important;
}

.config-deep-4 {
  margin-left: 60px !important;
}

.config-deep-5 {
  margin-left: 75px !important;
}

.config-deep-6 {
  margin-left: 90px !important;
}

.settings-inputs-container.config-deep-1 {
  grid-template-columns: 310px 1fr;
}

.settings-inputs-container.config-deep-2 {
  grid-template-columns: 295px 1fr;
}

.settings-inputs-container.config-deep-3 {
  grid-template-columns: 280px 1fr;
}

.settings-inputs-container.config-deep-4 {
  grid-template-columns: 265px 1fr;
}

.settings-inputs-container.config-deep-5 {
  grid-template-columns: 250px 1fr;
}

.settings-inputs-container.config-deep-6 {
  grid-template-columns: 235px 1fr;
}

.settings .buttons-section {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  position: sticky !important;
  z-index: 100;
  top: 0px;
}

.settings .buttons-section .checkbox {
  margin-right: 20px;
}

.settings-textfield-wrapper .input {
  width: 100%;
}

.settings-editbox-block textarea.big-field {
  min-height: 100px;
  resize: both;
}

.settings-inputs-subsection {
  display: flex;
  gap: 15px;
  align-items: center;
}
