.img-up {
  transform: rotate(180);
}

.stats-arrow {
  width: 25px !important;
  display: inline-block !important;
}

.graph-tooltip {
  visibility: hidden;
  background-color: teal;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  top: 0;
  left: 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 10;
  transition: visibility 0.1s linear, opacity 0.1s linear;
}

.graph-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 10;

  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: teal transparent transparent transparent;
}

@media only screen and (max-width: 2000px) {
  .ui.grid > .row > :not(:last-child) {
    padding-bottom: 2em;
  }
}
