.video-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.video-button-container button.red {
  background-color: #e5544d !important;
}

.video-button-container button.red:hover {
  background-color: #d94d47 !important;
}

.video-button-container button.grey {
  background-color: #9c9c9c !important;
  border: 2px solid #6c6c6c;
}
