.speaker-test {
  white-space: nowrap;
  font-weight: 600;
  margin: 0 0 0 21px;
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  background-color: white;
  color: black;
  text-align: left;
  font-size: 16px;
  border: 1px solid #e8dede;
  cursor: pointer;
  font-family: sans-serif;
  line-height: 1.15;
  min-width: 120px;
}
