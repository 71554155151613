#agents-stats .segment {
  display: inline-block;
  margin-right: 50px;
}

.AgentsList {
  padding-top: 50px;
}

.AgentsList .enterer {
  height: 10px;
}
