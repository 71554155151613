.Conversation {
}

.Conversation .enterer {
  height: 10px;
}

.Conversation .lenterer {
  padding: 5px;
}

.Conversation .one-label-conversation {
  text-align: center;
  height: 100%;
  text-overflow: ellipsis;
  font-size: 15px;
  border: 1px solid lightgray;
  border-radius: 5px;
  overflow: hidden;
  padding: 10px;
}

.Conversation .one-label-conversation ul {
  margin: 0;
}

.Conversation .one-label-conversation-padder {
  padding: 3px;
  width: 33%;
}

.Conversation .two-in-column {
  display: inline-block;
  padding: 3px;
  width: calc(50% - 6px);
}

.Conversation .label-conversation-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
}

.Conversation .react-player-and-notes {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Conversation .notes-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: flex-start;
  width: 100%;
}

.Conversation .notes-input-and-button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

.Conversation .all-notes {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  /* height: 50vh; */
  height: 100%;
  overflow-y: auto;
  padding: 10px;
}

.Conversation .one-note-container {
  padding: 30px;
  width: 100%;
  background-color: rgb(242, 246, 246);
  border-radius: 10px;
  box-shadow: 0px 0px 7px -4px black;
  pointer-events: none;
}

.Conversation .one-note {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  padding: 10px;
  border-radius: 10px;
  /* border: 1px solid gray; */
  background-color: white;
}

.Conversation .one-note-label {
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 10px;
}

.Conversation .one-note-time {
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}

.Conversation .one-note-text {
  font-size: 17px;
}

.Conversation .input-note {
  padding: 10px;
  font-size: 18px;
  width: 100%;
  border: 1px solid rgba(0, 133, 124, 1);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: none;
  transition: border 0.1s;
}

.Conversation .input-note:focus {
  outline: none;
  border: 1px solid rgba(0, 133, 124, 0.7);
  border-right: none;
}

.Conversation .button-note {
  padding: 10px;
  font-size: 18px;
  background-color: rgba(0, 133, 124, 1);
  color: white;
  border: 1px solid rgba(0, 133, 124, 1);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-left: none;
  cursor: pointer;
  transition: all 0.1s;
}

.Conversation .button-note:hover {
  background-color: rgba(0, 133, 124, 0.7);
  border: 1px solid rgba(0, 133, 124, 0.7);
  border-left: none;
}

.Conversation .labels-maker {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Conversation .line-of-labels {
  width: 100%;
  height: 150px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Conversation .timeline {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: center;
  padding-top: 100px;
  width: calc(100% - 25px);
}

.Conversation .label-text {
  font-size: 16px;
  width: max-content;
  align-self: center;
  max-width: 100px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Conversation .label-text:hover {
  overflow: visible;
  border: 1px solid black;
  background-color: white;
  z-index: 10;
  padding: 4px;
  border-radius: 3px;
  cursor: default;
  white-space: normal;
}

.Conversation .line {
  position: relative;
  top: 9px;
  width: 100%;
  height: 9px;
  display: flex;
  /* border-top: 8px solid gray; */
}

.Conversation .progress-line {
  border-top: 8px solid #3d3d3d;
  height: 1px;
  transition-duration: 100ms;
  transition-property: width;
}

.Conversation .rest-line {
  border-top: 8px solid gray;
  height: 1px;
  transition-duration: 100ms;
  transition-property: width;
}

.Conversation .label-line {
  height: 1px;
  /* width: 100%; */
  left: 0;
  border-top: 8px solid red;
}

.Conversation .label-line-text {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
}

.Conversation .label-line-text-bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 118px;
  left: 0%;
  right: 100%;
}

.Conversation .horizontal-line-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
}

.Conversation .horizontal-line {
  height: 20px;
  border-left: 2px solid black;
}

.Conversation .dot {
  position: absolute;
  /* top: 0; */
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: white;
  border: 4px solid teal;
  cursor: pointer;
}

.Conversation .note-glowing {
  animation: glow 1s 3 ease-in-out;
}

.Conversation .navgigation-table-section {
  padding: 100px 20px 0px;
  width: 100%;
}

.Conversation .navgigation-table-section .segment {
  width: 100%;
}

.Conversation .space {
  padding-top: 100px;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px 0px rgba(0, 133, 124, 1);
  }
  50% {
    box-shadow: 0 0 5px 3px rgba(0, 133, 124, 1);
  }
  100% {
    box-shadow: 0 0 5px 0px rgba(0, 133, 124, 1);
  }
}
