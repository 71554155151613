#scheduledSessionsTable td {
  word-break: break-all;
}
#scheduledSessionsTable tbody {
  overflow-y: scroll;
  height: 200px;
  display: block;
}
#scheduledSessionsTable thead,
#scheduledSessionsTable tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* even columns width , fix width of table too*/
}
#scheduledSessionsTable {
  margin-top: 30px;
}
#schedule-session-modal .close.icon {
  color: #5d5d5d;
}
