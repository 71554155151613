.AddLabel {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 25px;
}

.AddLabel button {
  border: none;
  outline: none;
}

.AddLabel .enterer {
  height: 10px;
}

.AddLabel .bigger-enterer {
  height: 20px;
}

.AddLabel .submit-button {
  font-size: 16px;
  width: 200px;
  padding: 10px;
  border: 1px solid rgba(0, 133, 124, 1);
  background-color: rgba(0, 133, 124, 1);
  color: white;
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.1s linear;
}

.AddLabel .submit-button:hover {
  border: 1px solid rgba(0, 133, 124, 0.7);
  background-color: rgba(0, 133, 124, 0.7);
  color: white;
  transition: background-color 0.1s linear;
}

.AddLabel h1 {
  font-size: 25px;
  font-weight: 500;
}
