.client-card_wrapper {
  padding: 2rem !important;
}

@media (max-width: 767px) {
  .client-card_list {
    width: 100%;
    justify-content: space-evenly;
  }

  .client-card {
    padding: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .client-card_wrapper {
    margin: 0 0.5rem !important;
    padding: 1rem !important;
  }
}

.assistant-page {
  position: absolute;
  top: 16px;
  right: 16px;
  width: fit-content;
  padding: 20px;
  font-size: 20px;
  color: black;
  font-weight: 600;
  background-color: white;
  border: 1px solid #e2e2e2;
}
